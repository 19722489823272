const state = {
    tabAll: {
        tabOrganizations: null,
        tabFounders: null
    },
    tabOrganizations: {
        items: null,
        paginationData: {}
    },
    tabFounders: {
        items: null,
        paginationData: {}
    }
}

const getters = {
    getOrganizationsInTabAll(contex) {
        return contex.tabAll.tabOrganizations;
    },
    getFoundersInTabAll(contex) {
        return contex.tabAll.tabFounders;
    },
    getOrganizationsItems(contex) {
        return contex.tabOrganizations.items;
    },
    getOrganizationsPaginationData(contex) {
        return contex.tabOrganizations.paginationData;
    },
    getFoundersItems(contex) {
        return contex.tabFounders.items;
    },
    getFoundersPaginationData(contex) {
        return contex.tabFounders.paginationData;
    }
}

const mutations = {
    setOrganizationsInTabAll(contex, payload) {
        contex.tabAll.tabOrganizations = payload;
    },
    setFoundersInTabAll(contex, payload) {
        contex.tabAll.tabFounders = payload;
    },
    setOrganizationsItems(contex, payload) {
        contex.tabOrganizations.items = payload;
    },
    setOrganizationsPaginationData(contex, payload) {
        contex.tabOrganizations.paginationData = payload;
    },
    setFoundersItems(contex, payload) {
        contex.tabFounders.items = payload;
    },
    setFoundersPaginationData(contex, payload) {
        contex.tabFounders.paginationData = payload;
    },
    resetTabsData(contex) {
        contex.tabAll = { tabOrganizations: null, tabFounders: null };
        contex.tabOrganizations = { items: null, paginationData: {} };
        contex.tabFounders = { items: null, paginationData: {} };
    }
}


export default {
    state,
    getters,
    mutations
}