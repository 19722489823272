import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import SearchView from '@/views/SearchView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      nameRU: 'Дом',
    },
    component: HomeView
  },
  {
    path: '/search/:type',
    name: 'search',
    meta: {
      nameRU: 'Поиск',
    },
    component: SearchView,
  },
  {
    path: '/search/company/:inn',
    children: [
      {
        path: '',
        name: 'company',
        meta: {
          nameRU: 'Организация',
        },
        component: () => import('@/views/CompanyView.vue')

      },
      {
        path: 'license',
        name: 'licenses',
        meta: {
          nameRU: 'Лицензии',
        },
        component: () => import('@/views/LicenseSearchView.vue')
      },
      {
        path: 'license/:id',
        name: 'licenseDetails',
        meta: {
          nameRU: 'Подробности о лицензии'
        },
        component: () => import('@/views/LicenseDetailsView.vue')
      }
      
    ]
  },
  {
    path: '/search/tradeMark',
    name: 'tradeMark',
    meta: {
      nameRU: 'Товарный знак',
    },
    component: () => import('@/views/TradeMarkView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/NotFoundView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from) => {
  if (to.name == 'search') {
    if ( !['all', 'organizations', 'founders'].includes(to.params.type.toLowerCase())) {
      return { name: 'notFound'}
    }
  }
})
export default router
