import { createStore } from 'vuex'
import companySearchStore from './companySearchStore'
import companyBasicDetailsStore from './companyBasicDetailsStore';
import paginationStore from './PaginationStore';
import searchTabsStore from './searchTabsStore';
import statisticsStore from './statisticsStore';
import router from '../router';
export default createStore({
  state: {
    inProcess: false,
    errorMessage: '',
    Token: '',
    UserName: localStorage.getItem('setUserName'),
    isAuth: localStorage.getItem('Token'),
    breadcrumb: localStorage.getItem('Breadcrumb') ? JSON.parse(localStorage.getItem('Breadcrumb')) : [
      {
        id: 'home',
        img: 'home.png',
        name: '',
        url: '/'
      }
    ]
  },
  getters: {
    getProcess(state) {
      return state.inProcess;
    },
    getErrorMessage(state) {
      return state.errorMessage;
    },
    getBreadcrumb(state) {
      return state.breadcrumb;
    },
    getAuth(state) {
      return state.isAuth
    },
    getUserName(state) {
      return state.UserName;
    },
    Token(state) {
      return state.Token;
    }
  },
  mutations: {
    setIsProcess(state, boolen) {
      // state.inProcess = boolen;

      if (boolen == true) {
        state.inProcess += 1;
        state.errorMessage = '';
      }
      else {
        state.inProcess -= 1;
      }
      window.setTimeout(function () {
        if (boolen) {
          state.inProcess = 0;
        }
      },20000);
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    setBreadcrumb(state, payload) {
      state.breadcrumb = payload;
      localStorage.setItem("Breadcrumb", JSON.stringify( payload));
    },
    setAuth(state, token) {
      state.isAuth = token;
    },
    setUserName(state, name) {
      state.UserName = name;
    }
    },
    actions: {
    },
  modules: {
    companySearchStore,
    companyBasicDetailsStore,
    paginationStore,
    statisticsStore,
    searchTabsStore
  }
})
