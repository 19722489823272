import CompanyService from "@/services/companyService";

const state = {
    searchedCompanies: [],
    searchedFounders: [],
    searchSuggestion: [],
    searchText: '',
    activeTab: ''

}

const getters = {
    getSearchedCompanies(state) {
        return state.searchedCompanies;
    },
    getSearchedFounders(state) {
        return state.searchedFounders;
    },
    getSearchText(state) {
        return state.searchText;
    },
    getSearchSuggestion(state) {
        return state.searchSuggestion;
    },
    getActiveTab(state) {
        return state.activeTab;
    }
}

const mutations = {
    setSearchedCompanies(state, componies) {
        state.searchedCompanies = componies;
    },
    setSearchedFounders(state, payload) {
        return state.searchedFounders = payload;
    },
    setSearchText(state, text) {
        state.searchText = text;
    },
    setSearchSuggestion(state, payload) {
        state.searchSuggestion = payload;
    },
    setActiveTab(state, payload){
        state.activeTab = payload;
    }
}

const actions = {
    async searchCompany_api(contex, obj) {
        contex.commit('setIsProcess', true);
        const response = await CompanyService.searchCompanies(obj.searchText,obj.pageSize, obj.pageSize*(obj.currentPage-1));
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    contex.commit('setSearchedCompanies', responseData.data);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                    contex.commit('setSearchedCompanies', []);

                }
            }
            else {
                contex.commit('setErrorMessage', response);
                contex.commit('setSearchedCompanies', []);

            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
        contex.commit('setIsProcess', false);

    },
    async searchFounders_api(contex, obj) {
        contex.commit('setIsProcess', true);
        
        const response = await CompanyService.searchFounders(obj.searchText,obj.pageSize, obj.pageSize*(obj.currentPage-1));
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    contex.commit('setSearchedFounders', responseData.data);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                    contex.commit('setSearchedFounders', []);

                }
            }
            else {
                contex.commit('setErrorMessage', response);
                contex.commit('setSearchedFounders', []);

            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
        contex.commit('setIsProcess', false);

    },
    async searchSuggestion_api(contex, searchText) {
        contex.commit('setSearchSuggestion', [])
        contex.commit('resetPagination');
        const response = await CompanyService.searchForSuggestion(searchText);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companies = responseData.data;
                    contex.commit('setSearchSuggestion', companies);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }

    }
}


export default {
    state,
    getters,
    mutations,
    actions
}