const state = {
    totalCount: 0,
    pageSize : 10,
    currentPage: 1,
    totalPage: 1,
    hasPrevious: false,
    hasNext:  true,
}

const getters = {
    getTotalPages(contex) {
        return contex.totalPage;
    },
    getCurrentPage(contex) {
        return contex.currentPage;
    },
    getHasPrevious(contex) {
        return contex.hasPrevious;
    },
    getHasNext(contex) {
        return contex.hasNext;
    },
    getPageSize(contex) {
        return contex.pageSize;
    }
}

const mutations = {
    resetPagination(contex) {
        contex.totalCount = 0;
        contex.currentPage = 1;
        contex.totalPage = 1;
        contex.hasPrevious = false;
        contex.hasNext = false; 
    },
    setTotalPages(contex, payload) {
        contex.totalCount = payload;
        contex.totalPage = Math.ceil(payload/contex.pageSize);
        contex.hasNext = contex.totalPage > 1;
    },
    setPageSize(contex, payload) {
        if (isNaN(payload) || !(payload > 0) || payload > 50) {
            payload = 10;
          }
        contex.pageSize = payload;
        contex.totalPage = Math.ceil(contex.totalCount/payload);
        contex.currentPage = 1;
    },
    setCurrentPage (contex, payload) {
        if (isNaN(payload) || !(payload > 0)) {
        payload = 1;
        }
        contex.currentPage = payload;
        contex.hasPrevious = payload > 1;
        contex.hasNext = payload < contex.totalPage;
    }
}

const actions = {
    paginationService(contex, params) {
        return {
            totalItemsCount: params.totalItemsCount,
            totalPage : Math.ceil(params.totalItemsCount / params.pageSize),
            currentPage: params.currentPage,
            pageSize: params.pageSize,
            hasPrevious: params.currentPage > 1,
            hasNext: params.currentPage < Math.ceil(params.totalItemsCount / params.pageSize)
        };
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}