<template>
    <MainHeader></MainHeader>
    <section>
        <div class="Main-frame  " :class="(this.$route.name != 'search') ? 'p-0' : ' pt-5 pb-5'">
            <div class="container">
                <div class="wrapper">
                    <slot name="Main-frame">

                    </slot>
                    <BreadcrumbComponent class="d-none d-lg-flex" v-if="this.$route.name != 'home'"></BreadcrumbComponent>
                </div>
            </div>
        </div>

    </section>
    <slot></slot>

    <MainFooter></MainFooter>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import BreadcrumbComponent from '@/components/Breadcrumb.vue'

export default {
    components: {
        MainHeader,
        MainFooter,
        BreadcrumbComponent
    }
}
</script>
