import CompanyService from "@/services/companyService.js";
import router from "@/config/router/index";
const state = {
    companyBasicDetails: '',
    companyTenders: '',
    companyOrders: '',
    companyFounders: '',
    companyLicensies: '',
    companyTradeMarks: '',
    tradeMarkDetails: '',
    registrationInfo: '',
    reportFile: '',
    companyBranches: '',
    licenseDetails: ''
}

const getters = {
    getCompanyBasicDetails(state) {
        return state.companyBasicDetails;
    },
    getCompanyBranches(state) {
        return state.companyBranches;
    },
    getCompanyFounders(state) {
        return state.companyFounders;
    },
    getCompanyTenders(state) {
        return state.companyTenders;
    },
    getCompanyOrders(state) {
        return state.companyOrders;
    },
    getCompanyLicensies(state) {
        return state.companyLicensies;
    },
    getCompanyTradeMarks(state) {
        return state.companyTradeMarks;
    },
    getTradeMarkDetails(state) {
        return state.tradeMarkDetails;
    },
    getReportFileUrl(state) {
        return state.reportFile;
    },
    getRegistrationInfo(state) {
        return state.registrationInfo;
    },
    getLicenseDetails(state) {
        return state.licenseDetails;
    }
}

const mutations = {
    clearTemp(state) {
        state.companyBasicDetails = '';
        state.companyTenders = '';
        state.companyOrders = '';
        state.companyFounders = '';
        state.companyLicensies = '';
        state.companyTradeMarks = '';
        state.tradeMarkDetails = '';
        state.registrationInfo = '';
        state.reportFile = '';
        state.companyBranches = '';
        state.licenseDetails = ''
    },
    setCompanyBasicDetails(state, companyBasicDetails) {
        state.companyTenders = '';
        state.companyOrders = '';
        state.companyTradeMarks = '';
        state.companyBasicDetails = companyBasicDetails;
    },
    setCompanyBranches(state, branches) {
        state.companyBranches = branches;
    },
    setCompanyTenders(state, tenders) {
        state.companyTenders = tenders;
    },
    setCompanyOrders(state, orders) {
        state.companyOrders = orders;
    },
    setCompanyFounders(state, payload) {
        state.companyFounders = payload;
    },
    setCompanyLicensies(state, payload) {
        return state.companyLicensies = payload;
    },
    setCompanyTradeMarks(state, tradeMarks) {
        state.companyTradeMarks = tradeMarks;
    },
    setTradeMarkDetails(state, tradeMarkDetails) {
        state.tradeMarkDetails = tradeMarkDetails;
    },
    setReportFileUrl(state, url) {

        state.reportFile = process.env.VUE_APP_API_URL + url;
    },
    setRegistrationInfo(state, payload) {
        state.registrationInfo = payload;
    },
    setLicenseDetails(state, payload) {
        state.licenseDetails = payload;
    }
}

const actions = {
    async companyBasicDetails_api(contex, inn) {
        contex.commit('setCompanyBasicDetails', '');
        try {
            const response = await CompanyService.companyBasicDetails(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyBasicDetails', companyDetails);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyBranches_api(contex, inn) {
        contex.commit('setCompanyBranches', '');
        try {

            const response = await CompanyService.getCompanyBranches(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyBranches', companyDetails);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyFounders_api(contex, inn) {
        contex.commit('setCompanyFounders', '');
        try {
            const response = await CompanyService.companyFounders(inn);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyFounders', companyDetails);
                    }
                    else if (responseData.StatusCode == 429) {
                        contex.commit('setCompanyFounders', "Payment");
    
                        contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');
    
                    }
                    else if (responseData.StatusCode == 401) {
                        contex.commit('setCompanyFounders', "Payment");
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.Title);
            router.push({ name: 'notFound' });
        }

    },
    async companyTenders_api(contex, inn) {
        contex.commit('setCompanyTenders', '');
        const response = await CompanyService.companyTenders(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyTenders', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyOrders_api(contex, inn) {
        contex.commit('setCompanyOrders', '');
        const response = await CompanyService.companyOrders(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyOrders', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyLicensies_api(contex, obj) {
        contex.commit('setCompanyLicensies', '');
        let response = '';
        if (obj.pinfl != undefined) {
            response = await CompanyService.getLicensiesIndividuals(obj);
        }
        else {
            response = await CompanyService.getLicensiesLegal(obj);
        }
        if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setCompanyLicensies', companyDetails);
                    }
                    else if (responseData.StatusCode == 429) {
                        contex.commit('setCompanyLicensies', "Payment");
        
                        contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');
        
                    }
                    else if (responseData.StatusCode == 401) {
                        contex.commit('setCompanyLicensies', "Payment");
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                    }
                }
                else {
                    contex.commit('setErrorMessage', response);
                }
        }

        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async registrationInfo_api(contex, inn) {
        contex.commit('setRegistrationInfo', '');
        let response = '';
        if (inn.length > 9) {

            response = await CompanyService.getIndividualRegistrationInfo(inn);
        }
        else {
            response = await CompanyService.getCompanyRegistrationInfo(inn);
        }
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setRegistrationInfo', companyDetails);
                }
                else if (responseData.StatusCode == 429) {
                    contex.commit('setRegistrationInfo', "Payment");
    
                    contex.commit('setErrorMessage', 'Ваш лимит на сегодня закончился');
    
                }
                else if (responseData.StatusCode == 401) {
                    contex.commit('setRegistrationInfo', "Payment");
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async licenseDetails_api(contex, id) {
        contex.commit('setLicenseDetails', '');
        const response = await CompanyService.getLicenseDetails(id);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    if (companyDetails.data) {
                        contex.commit('setLicenseDetails', companyDetails.data[0]);
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' })
                    }
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async companyTradeMarks_api(contex, inn) {
        contex.commit('setCompanyTradeMarks', '');
        const response = await CompanyService.companyTradeMarks(inn);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let companyDetails = responseData.data;
                    contex.commit('setCompanyTradeMarks', companyDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
        }
    },
    async tradeMarkDetails_api(contex, id) {
        contex.commit('setTradeMarkDetails', '');
        const response = await CompanyService.tradeMark(id);
        if (response.status == 200) {
            if (response.data) {
                let responseData = response.data;
                if (responseData.success) {
                    let tradeMarkDetails = responseData.data;
                    contex.commit('setTradeMarkDetails', tradeMarkDetails);
                }
                else {
                    contex.commit('setErrorMessage', responseData.errorMessage);
                }
            }
            else {
                contex.commit('setErrorMessage', response);
                router.push({ name: 'notFound' });
            }
        }
        else {
            //error page
            contex.commit('setErrorMessage', response);
            router.push({ name: 'notFound' });

        }
    },
    async generateReportFile_api(contex, inn) {
        contex.commit('setReportFileUrl', '');
        try {

            const response = await CompanyService.generateReportFile(inn, 2);
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let url = responseData.data;
                        contex.commit('setReportFileUrl', url);
                    }
                    else {
                        contex.commit('setIsProcess', false);
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({ name: 'notFound' });
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.errorMessage);
                    router.push({ name: 'notFound' });

                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.errorMessage);
                router.push({ name: 'notFound' });
            }
        }
        catch (e) {
            contex.commit('setErrorMessage', e.response.data.errorMessage);
            router.push({ name: 'notFound' });
        }

    },
}


export default {
    state,
    getters,
    mutations,
    actions
}