import CompanyService from "@/services/companyService";

const state = {
    topAuthorizedFundCompanies:[],
    topTradeMarksCompanies: []
}

const getters = {
    getTopAuthorizedFundCompanies(store) {
        return store.topAuthorizedFundCompanies;
    },
    getTopTradeMarksCompanies(store) {
        return store.topTradeMarksCompanies;
    }
}

const mutations =  {
    setTopAuthorizedFundCompanies(store, payload) {
         store.topAuthorizedFundCompanies = payload;
    },
    setTopTradeMarksCompanies(store, payload) {
         store.topTradeMarksCompanies = payload;
    }
}

const actions = {
    async topAuthorizedFundCompanies_api(contex) {
        contex.commit('setTopAuthorizedFundCompanies', []);
        try {

            const response = await CompanyService.topCompaniesByAuthorizedFund();
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setTopAuthorizedFundCompanies', companyDetails.slice(0,5));
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({name: 'notFound'});
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({name: 'notFound'});
                    
                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({name: 'notFound'});
            }
        } 
        catch (e) {
            console.log(e);
            contex.commit('setErrorMessage', e.response.data.Title);
                router.push({name: 'notFound'});
        }

    },
    async topTradeMarksCompanies_api(contex) {
        contex.commit('setTopTradeMarksCompanies', []);
        try {

            const response = await CompanyService.topCompaniesByTradeMarksCount();
            if (response.status == 200) {
                if (response.data) {
                    let responseData = response.data;
                    if (responseData.success) {
                        let companyDetails = responseData.data;
                        contex.commit('setTopTradeMarksCompanies', companyDetails.slice(0,5));
                    }
                    else {
                        contex.commit('setErrorMessage', responseData.errorMessage);
                        router.push({name: 'notFound'});
                    }
                }
                else {
                    contex.commit('setErrorMessage', response.data.Title);
                    router.push({name: 'notFound'});
                    
                }
            }
            else {
                //error page
                contex.commit('setErrorMessage', response.data.Title);
                router.push({name: 'notFound'});
            }
        } 
        catch (e) {
            console.log(e);
            contex.commit('setErrorMessage', e.response.data.Title);
                router.push({name: 'notFound'});
        }

    },
}

export default {
    state,
    getters,
    mutations,
    actions
}