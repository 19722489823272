import axios from './axios.js'
import store from '@/config/store/index.js';
const AuthService = { 
    async getTokenWithFaktura(code) {
        let query = "code=" + code + "&redirectUri=" + (window.location.href.split('?')[0]);
        let result = await axios.get("auth/get-access-token?" + query);
        return result;
    },
    saveToken(token) {
        localStorage.setItem('Token',token);
        store.state.Token = token;
        store.commit('setAuth', token);
    },
    saveUserName(name) {
        localStorage.setItem('setUserName',name);
        store.commit('setUserName', name);
    },
    getToken() {
        return localStorage.getItem('Token');
    },
    logOut() {
        localStorage.removeItem('Token');
        store.commit('setAuth', null);
        store.commit('setUserName', '');
    },
    Authorization() {
        return this.getToken() ?? false
    }
}


export default AuthService;