<template>
  <MainLayout>
    <template v-slot:Main-frame>
      <div class="frame-block">
        <div class="frame-info">
          <h1>
            Детальная проверка контрагентов
          </h1>
          <h5>Информация о более чем 15 миллионах организаций, зарегистрированных в
            Узбекистане
          </h5>
        </div>
      </div>
    </template>
    <section>
      <div class="Search-page">
        <div class="Main-frame p-0" style="margin: -45px 0 0 0; background: transparent;">
          <div class="container">
            <div class="wrapper">
              <searchComponent></searchComponent>
            </div>
          </div>
        </div>
      </div>
      <tabAllComponent v-if="getActiveTab() == 'All'" key="All"></tabAllComponent>
      <companyListComponent v-if="getActiveTab() == 'Organizations'" class="company-list-component" key="Organizations">
      </companyListComponent>
      <foundersListComponent v-if="getActiveTab() == 'Founders'" class="company-list-component" key="Founders">
      </foundersListComponent>
    </section>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import searchComponent from '@/components/SearchComponent.vue'
import tabAllComponent from '@/components/TabAllComponent.vue'
import companyListComponent from '@/components/OrganizationsListComponent.vue'
import foundersListComponent from '@/components/FoundersListComponent.vue'
import MainFooter from '@/components/MainFooter.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'HomeView',
  methods: {
    ...mapGetters([
      'getBreadcrumb',
      'getSearchText',
      'getCurrentPage',
      'getActiveTab',
      'getPageSize',
      'getOrganizationsPaginationData',
      'getFoundersPaginationData',

    ]),
    ...mapMutations([
      'setBreadcrumb',
      'setSearchText',
      'setCurrentPage',
      'setActiveTab',
      'setPageSize',
      'setOrganizationsInTabAll',
      'setFoundersInTabAll',
      'setOrganizationsItems',
      'setFoundersItems',
      'setOrganizationsPaginationData',
      'setFoundersPaginationData'
    ])
  },
  components: {
    MainLayout,
    searchComponent,
    companyListComponent,
    MainFooter,
    foundersListComponent,
    tabAllComponent
  },
  computed: {
    activeTab() {
      return this.getActiveTab();
    },
    searchText() {
      return this.getSearchText();
    }
  },
  watch: {
    'activeTab'(to, from) {
      if (from != '') {
        if (to == 'All') {
          this.$router.replace({ name: 'search', params: { type: to }, query: { text: this.getSearchText() } });
        }
        else if (to == 'Organizations') {
          let page = this.getOrganizationsPaginationData().currentPage ? this.getOrganizationsPaginationData().currentPage : 1;
          this.$router.replace({ name: 'search', params: { type: to }, query: { text: this.getSearchText(), pageSize: this.getPageSize(), page: page } });
        }
        else if (to == 'Founders') {
          let page = this.getOrganizationsPaginationData().currentPage ? this.getFoundersPaginationData().currentPage : 1;
          this.$router.replace({ name: 'search', params: { type: to }, query: { text: this.getSearchText(), pageSize: this.getPageSize(), page: page } });
        }
      }
    },
    'searchText'(to, from) {
      if (from != '') {
        this.$router.replace({ name: 'search', params: { type: this.getActiveTab() }, query: { text: this.getSearchText(), pageSize: 10, page: 1 } });
      }
    }
  },

  beforeMount() {
    let typeSearch = this.$route.params.type.toLowerCase();
    if (['all', 'organizations', 'founders'].includes(typeSearch)) {
      let activeTab = typeSearch.charAt(0).toUpperCase() + typeSearch.slice(1);
      this.setActiveTab(activeTab)
    }
    else {
      this.setActiveTab('All');
    }
    let searchtext = this.$route.query.text;
    this.setSearchText(searchtext);
    let currentPage = !isNaN(this.$route.query.page) ? +this.$route.query.page : 1;
    let pageSize = !isNaN(this.$route.query.pageSize) ? +this.$route.query.pageSize : 10;

    this.setPageSize(pageSize);
    this.setCurrentPage(currentPage);

  }
}
</script>