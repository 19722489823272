import { createApp } from 'vue'
import App from './App.vue'
import router from './config/router/index.js'
import store from './config/store/index.js'
//import styles 
import './assets/styles/bootstrap.min.css'
import './assets/styles/styles.css'
import './assets/styles/media.css'
import './assets/font/LabGrotesque/stylesheet.css'


createApp(App).use(store).use(router).mount('#app')
