<template>
    <div class="card">
        <p class="fw-bold mb-3">Искать по названию или ИНН</p>
        <form @submit="(e) =>{e.preventDefault(); changeSearchText()}"  class="input-group">
            <input type="text" v-model="searchText" class="form-control" key aria-describedby="basic-addo">
            <div class="input-group-append ml-n5">
                <button class="btn " style="margin-left: -40px;z-index: 3; background-color: transparent; border:none"
                    type="submit">
                    <i class="fa fa-search"></i></button>
            </div>
        </form>
        <div class="mt-3 filter">
            
            <p :class="getActiveTab() == 'All' ? 'active': ''" @click="changeActiveTab('All')">Все</p>
            <p :class="getActiveTab() == 'Organizations' ? 'active': ''" @click="changeActiveTab('Organizations')">Организации</p>
            <p :class="getActiveTab() == 'Founders' ? 'active': ''" @click="changeActiveTab('Founders')">Учредители</p>
            <!-- <p :class="getActiveTab() == 'Managers' ? 'active': ''" @click="changeActiveTab('Managers')">Руководители</p>
            <p :class="getActiveTab() == 'Trademarks' ? 'active': ''" @click="changeActiveTab('Trademarks')">Товарные знаки</p>  -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    data() {
        return {
            searchText: ''
        }
    },
    methods: {
        ...mapMutations([
            'setSearchText',
            'setActiveTab',
            'resetTabsData'
        ]),
        ...mapGetters([
            'getActiveTab',
            'getSearchText'
        ]),
        changeActiveTab(element) {
            this.setActiveTab(element);
        },
        changeSearchText() {
            if (this.searchText != this.getSearchText()){
                this.setSearchText(this.searchText);
                this.resetTabsData();
            }
            this.setActiveTab('All');
        }
    },
    async mounted() {
         this.searchText = this.getSearchText();
    }
}
</script>