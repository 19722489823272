<template>
    <div class="container">
        <div class="col-lg-9 col-12" v-if="pagination.totalPage > 1">

            <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item " :class="pagination.hasPrevious ? '' : ' disabled'">
                        <button class="page-link" @click="redirect(pagination.currentPage - 1)">Назад</button>
                    </li>

                    <li class="page-item" v-if="pagination.currentPage > 1"> <button class="page-link" @click="redirect(1)">1</button></li>
                    <li class="page-item disabled" v-if="pagination.currentPage > 3"> <button class="page-link" >...</button></li>
                    <li class="page-item" v-if="pagination.currentPage - 1 > 1 "> <button class="page-link" @click="redirect( pagination.currentPage - 1)">{{ pagination.currentPage - 1 }}</button></li>
                    <li class="page-item active" v-if="pagination.currentPage"> <button class="page-link"  @click="redirect(pagination.currentPage)">{{pagination.currentPage}}</button></li>
                    <li class="page-item " v-if=" pagination.currentPage + 1 < pagination.totalPage"> <button class="page-link" @click="redirect(pagination.currentPage+1)">{{pagination.currentPage + 1}}</button></li>
                    <li class="page-item disabled" v-if="pagination.currentPage + 2 < pagination.totalPage"> <button class="page-link">...</button></li>
                    <li class="page-item " v-if="pagination.currentPage < pagination.totalPage"> <button class="page-link" @click="redirect(pagination.totalPage)">{{pagination.totalPage}}</button></li>
                    
                    
                    <!-- <li class="page-item" v-for="item in pagination.totalPage" :key="item"
                        :class="(pagination.currentPage == item) ? ' active' : ''"><button class="page-link"
                            @click="redirect(item)">{{ item }}</button></li> -->
                    <li class="page-item" :class="pagination.hasNext ? '' : 'disabled'">
                        <button class="page-link" @click="redirect(pagination.currentPage+1)">Далее</button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    methods: {
        ...mapGetters([
            'getActiveTab',
            'getOrganizationsPaginationData',
            'getFoundersPaginationData'
        ]),
        redirect(page) {
            this.$emit('changePage', page);
        }
    },
    computed: {
        pagination() {
            if(this.getActiveTab() == 'Organizations') {
                return this.getOrganizationsPaginationData();
            }
            else if (this.getActiveTab() == 'Founders') {
                return this.getFoundersPaginationData();
            }
            else 
            {
                return {}
            }
        }
    }
}

</script>