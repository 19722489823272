<template>
    <form @submit="submitSearch(this.searchText)" class="input-group">
        <input id="InputSuggestion" type="text" v-model="searchText"
            placeholder="Название    /    ИНН    /    Генеральный директор" class="form-control" key
            aria-describedby="basic-addo" autocomplete="off" @input="this.debounce()" >
        <div class="input-group-append d-flex align-items-center">
            <button class="btn filter-btn" style="z-index: -1;" type="button">
                <icon class="fa fa-sliders"></icon> Фильтр
            </button>
            <button class="btn search-btn" type="submit">НАЙТИ</button>
        </div>
        <div v-if="showModal && ( searchSuggestion.companies || searchSuggestion.founders) && searchText" class="card p-2 card-for-company-names">
            <div class="card rounded p-0" v-if="searchSuggestion.companies" style="background-color: rgb(232, 240, 254);">
                <div class="card-header d-flex text-dark text-start">
                    <img src="../assets/images/company.png" class="me-2" width="24px" alt="companies">
                    <h5 class="m-0">
                        Организации
                    </h5>
                </div>
                <div class="card-body px-3" style="background-color: rgb(232, 240, 254);">
                    <div class="items" @click="clickCompany(company.inn || company.pinfl)" v-for="company in searchSuggestion.companies" :key="company" >
                        <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ company.companyName }}</p>
                    </div>
                </div>
            </div>
            <div class="card rounded p-0 mt-2" v-if="searchSuggestion.founders.length" style="background-color: rgb(232, 240, 254);">
                <div class="card-header d-flex text-dark text-start">
                    <img src="../assets/images/founders.png" class="me-2" width="26px" alt="companies">
                    <h5 class="m-0">
                        Учредители
                    </h5>
                </div>
                <div class="card-body px-3" style="background-color: rgb(232, 240, 254);">
                    <div class="items"  @click="submitSearch(founder.fullName)" v-for="founder in searchSuggestion.founders" :key="founder" >
                        <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ founder.fullName }}</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- <div class="filter">
            <p :class="activeFilterElement == 'All' ? 'active': ''" @click="activedFilterElement('All')">Все</p>
            <p :class="activeFilterElement == 'Organizations' ? 'active': ''" @click="activedFilterElement('Organizations')">Организации</p>
            <p :class="activeFilterElement == 'Founders' ? 'active': ''" @click="activedFilterElement('Founders')">Учредители</p>
            <p :class="activeFilterElement == 'Managers' ? 'active': ''" @click="activedFilterElement('Managers')">Руководители</p>
            <p :class="activeFilterElement == 'Trademarks' ? 'active': ''" @click="activedFilterElement('Trademarks')">Товарные знаки</p>
        </div> -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    data() {
        return {
            activeFilterElement: 'All',
            searchText: '',
            showModal: false,
            debounceFunction: null
        }
    },
    methods: {
        ...mapGetters([
            'getSearchSuggestion'
        ]),
        ...mapMutations([
            'setSearchSuggestion'
        ]),
        ...mapActions([
            'searchSuggestion_api'
        ]),
        activedFilterElement(element) {
            this.activeFilterElement = element
            this.submitSearch();
        },
        clickCompany(inn) {
            this.$router.push({name:'company', params: {inn: inn}});
        },
        submitSearch(text) {
            this.searchText = text;
            this.$router.push({ name: 'search',params: {type: 'All'}, query: { text: this.searchText, pageSize: 10, page:1 } });
        },
        debounce() {
            clearTimeout(this.debounceFunction);
            this.debounceFunction = setTimeout(() => {
                if (this.searchText != '') {
                    this.searchSuggestion_api(this.searchText);
                }
            },700);
        }
    },
    computed: {
        searchSuggestion() {
            return this.getSearchSuggestion();

        }
    },
    beforeMount() {
        this.searchText = this.$route.query.text;

    },
    mounted() {
        let inputSuggestion = document.getElementById('InputSuggestion');
        let self = this;
        window.addEventListener('click', function () {
            if (this.document.activeElement.id != 'InputSuggestion') {
                self.showModal = false;
            }
        })
        inputSuggestion.addEventListener('focus', function () {
            self.showModal = true;
        } )
    }
}
</script>