<template>
    <OrganizationsListComponent class="company-list-component"></OrganizationsListComponent>
    <FoundersListComponent class="company-list-component"></FoundersListComponent>
</template>

<script>
import OrganizationsListComponent from './OrganizationsListComponent.vue'
import FoundersListComponent from './FoundersListComponent.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    components: {
        OrganizationsListComponent,
        FoundersListComponent
    },
    methods: {
        ...mapGetters([
            'getSearchText',
            'getSearchedCompanies',
            'getPageSize',
            'getSearchedFounders',
            'getOrganizationsInTabAll',
            'getOrganizationsItems',
            'getFoundersItems',
            'getActiveTab'
        ]),
        ...mapMutations([
            'setOrganizationsInTabAll',
            'setFoundersInTabAll',
            'setOrganizationsItems',
            'setFoundersItems',
            'setOrganizationsPaginationData',
            'setFoundersPaginationData'
        ]),
        ...mapActions([
            'paginationService',
            'searchCompany_api',
            'searchFounders_api'
        ])
    },
    computed: {
        isActive() {
            return this.getActiveTab() == 'All';
        },
        searchText() {
            return this.getSearchText();
        }
    },
    watch: {
        async 'searchText' (to,from) {
            if(this.isActive) {
                let obj = {
                searchText: this.getSearchText(),
                currentPage: 1,
                pageSize: this.getPageSize()
            }
            await this.searchCompany_api(obj);
            await this.searchFounders_api(obj);
            this.setOrganizationsInTabAll(this.getSearchedCompanies().items);
            this.setFoundersInTabAll(this.getSearchedFounders().items);
            if (this.getOrganizationsItems() == null) {
                this.setOrganizationsItems(this.getSearchedCompanies().items);
                let paginationParams = {totalItemsCount: this.getSearchedCompanies().totalCount, pageSize: this.getPageSize(), currentPage: 1};
                this.setOrganizationsPaginationData(await this.paginationService(paginationParams));
            }
            if (this.getFoundersItems() == null) {
                this.setFoundersItems(this.getSearchedFounders().items);
                let paginationParams = {totalItemsCount: this.getSearchedFounders().totalCount, pageSize: this.getPageSize(), currentPage: 1};
                this.setFoundersPaginationData(await this.paginationService(paginationParams));
            }
            }
        }
    },
    async mounted() {
        if (this.getOrganizationsInTabAll() === null && this.isActive) {
            let obj = {
                searchText: this.getSearchText(),
                currentPage: 1,
                pageSize: this.getPageSize()
            }
            await this.searchCompany_api(obj);
            await this.searchFounders_api(obj);
            this.setOrganizationsInTabAll(this.getSearchedCompanies().items);
            this.setFoundersInTabAll(this.getSearchedFounders().items);
            if (this.getOrganizationsItems() == null) {
                this.setOrganizationsItems(this.getSearchedCompanies().items);
                let paginationParams = {totalItemsCount: this.getSearchedCompanies().totalCount, pageSize: this.getPageSize(), currentPage: 1};
                this.setOrganizationsPaginationData(await this.paginationService(paginationParams));
            }
            if (this.getFoundersItems() == null) {
                this.setFoundersItems(this.getSearchedFounders().items);
                let paginationParams = {totalItemsCount: this.getSearchedFounders().totalCount, pageSize: this.getPageSize(), currentPage: 1};
                this.setFoundersPaginationData(await this.paginationService(paginationParams));
            }
        }
    }

}
</script>

<style></style>