<template>
    <div class="breadcrumb-container">
        <div class="container ">
            <div class="breadcrumb">

                <div v-for="breadcrumb in breadcrumbs" class="breadcrumb-item">
                    <div>
                        <RouterLink class="d-flex breadcrumb-item" :to="breadcrumb.url">
                            <img  :src='`/img/${breadcrumb.id}.png`' height="20px"
                                :alt="breadcrumb.id">
                            <!-- <img v-else-if="breadcrumb.id == 'company'" src='../assets/images/company.png' height="20px"
                                :alt="breadcrumb.id">
                            <img v-else-if="breadcrumb.id == 'search'" src='../assets/images/search.png' height="20px"
                                :alt="breadcrumb.id">
                            <img v-else-if="breadcrumb.id == 'tradeMark'" src='../assets/images/trademark.png'
                                height="19px" :alt="breadcrumb.id"> -->
                            <p v-if="breadcrumb.id != 'home'" class="m-0 px-2 d-inline-block text-nowrap"
                                style="max-width: 350px;"
                                :class="breadcrumb.name != undefined ? (breadcrumb.name.length > 25) ? ' text-truncate px-2 pe-1' : 'pe-3' : ''">
                                {{ breadcrumb.name }}</p>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    methods: {
        ...mapGetters([
            'getBreadcrumb'
        ]),
        ...mapMutations([
            'setBreadcrumb'
        ])
    },
    computed: {
        breadcrumbs() {
            return this.getBreadcrumb()
        }
    },
    watch: {
        '$route.fullPath'(to, from) {
            if (to != '/') {

                let breadcrumb = this.getBreadcrumb();
                let routeName = this.$route.name;
                let searchBreadcrumb = {
                    id: routeName,
                    img: `${routeName}.png`,
                    name: this.$route.meta.nameRU,
                    url: this.$route.fullPath
                };
                if (!breadcrumb.find(element => element.id == routeName)) {
                    breadcrumb.push(searchBreadcrumb);
                } else {
                    breadcrumb[breadcrumb.findIndex(object => object.id == routeName)] = searchBreadcrumb;
                }
                breadcrumb = breadcrumb.slice(0, breadcrumb.findIndex(object => object.id == routeName) + 1);
                this.setBreadcrumb(breadcrumb);
            }
        }
    },
    mounted() {
        let breadcrumb = this.getBreadcrumb();
                let routeName = this.$route.name;
                let searchBreadcrumb = {
                    id: routeName,
                    img: `${routeName}.png`,
                    name: this.$route.meta.nameRU,
                    url: this.$route.fullPath
                };
                if (!breadcrumb.find(element => element.id == routeName)) {
                    breadcrumb.push(searchBreadcrumb);
                } else {
                    breadcrumb[breadcrumb.findIndex(object => object.id == routeName)] = searchBreadcrumb;
                }
                breadcrumb = breadcrumb.slice(0, breadcrumb.findIndex(object => object.id == routeName) + 1);
                this.setBreadcrumb(breadcrumb);
    }
}
</script>


<style>
.breadcrumb-container {
    height: 70px;
    /* background: linear-gradient(90deg, #0469A8 0%, #2291FF 100%) !important; */
    display: flex;
    align-items: center;
}

.breadcrumb {
    display: flex;
    margin-bottom: 0 !important;
    padding: 5px !important;
}

.breadcrumb-item {
    color: rgb(255, 255, 255);
}

.breadcrumb-item:hover {
    color: white;

}

.breadcrumb-item+.breadcrumb-item::before {
    color: white !important;
}
</style>